window._ = require('lodash');
// window.Vue = require('vue');
import Vue from 'vue'
import $ from 'jquery'
import 'datatables.net'

window.Vue = Vue;
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals ando tabs. This
 * code may be modified to fit the specific needs of your applicatin.
 */
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = $;

    window.dt = require('datatables.net-bs4');
    window.buttons = require('datatables.net-buttons');
} catch (e) {
}
import 'jquery-ui/ui/widgets/dialog.js';
import 'jquery-ui/ui/widgets/slider.js';
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('monitordata', require('./components/monitordata').default);

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });