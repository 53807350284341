/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('timepicker/jquery.timepicker.min');

var bootstrapButton = $.fn.button.noConflict();
$.fn.bootstrapBtn = bootstrapButton;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


initWidget = function (id, options) {
    $(id).dialog(options);
}

openWidget = function (object, id) {
    if (object !== null) {
        $('#' + id).dialog({
            position: {
                my: "left top",
                at: "top top",
                of: object
            }
        }).dialog('open');
    } else {
        $('#' + id).dialog({
            position: {
                my: "center",
                at: "center",
                of: window
            }
        }).dialog('open');
    }
}
 setSliders = function() {
    $(".slider").each(function () {
        let value = $(this).attr('data-value');
        $(this).slider({
            max: 100,
            min: 0,
            value: value,
            animate: 100,
            range: "min",
            step: 1
        })
    })
}
$(document).ready(function () {
    //datatables
    $(".datatable").DataTable({
        "searching": false,
        "lengthChange": false,
        "fnDrawCallback": function(oSettings) {
            if ($(this).find('tbody tr').length < 10) {
                $(this).parent().find('.dataTables_paginate').hide();
            }
        },
        "ordering": false
    });

    let filter = $(".filter");

    filter.keypress(function (event) {
        if (event.which === 13) {
            var url = window.location.href;
            url = url.split("#")[0].split("?")[0]
            url += "?" + $(".filter").serialize();
            location.href = url;
        }
    })
    filter.on('change', function () {
        var url = window.location.href;
        url = url.split("#")[0].split("?")[0]
        url += "?" + $(".filter").serialize();
        location.href = url;
    })
    setSliders();
});

window.toggleNavBar = function() {
    $(".pi_navbar > ul > li").not(".active").each(function () {
        $(this).slideToggle( "fast", function() {
            // Animation complete.
        });
    })
}
